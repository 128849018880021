#destinations-map {
	width: 100%;
	height: auto;

	.map-area {
		position: relative;
		transition: all 200ms ease-in-out;
		fill: darkgray;
		cursor: pointer;

		.map-area-path {
			opacity: .2;
		}

		&.hover,
		&:hover {
			z-index: 2;
			box-shadow: 0 0 0 rgba(black, .3);
			filter: drop-shadow(0 0 5px rgba(0, 0, 0, 30%));

			.map-area-path {
				opacity: 1;
			}
		}

		&.map-area__ch {
			fill: #893089;
		}

		&.map-area__pi {
			fill: #ffc900;
		}

		&.map-area__su {
			fill: #47824e;
		}

		&.map-area__kr {
			fill: #c9a951;
		}

		&.map-area__li {
			fill: #007bca;
		}

		&.map-area__to {
			fill: #f48244;
		}

		&.map-area__tr {
			fill: #e82717;
		}

		&.map-area__ck {
			fill: #3b8375;
		}

		&.map-area__nh {
			fill: #9c3;
		}
	}
}
