@import "variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~glightbox/dist/css/glightbox.min.css";
@import "../../../node_modules/vanillajs-datepicker/dist/css/datepicker.css";
@import "./crumbs/*";
@import "./components/*";
@import "./pages/*";

body,
html {
	overflow-x: hidden !important;
	min-width: 340px;
}

hr {
	opacity: .15;
}

:root {
	--theme-green: #13a538;
	--theme-grey: #e9e9e9;
	--theme-orange: #fd6629;
}
