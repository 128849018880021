.banner-gradient {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0%) 0%, rgba(0, 0, 0, 53%) 100%);

	&.bottom {
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0%) 80%, rgba(0, 0, 0, 75%) 100%);
	}
}
