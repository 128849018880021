.button {
	display: inline-block;
	text-decoration: none;
	color: #363636;
	background: none;
	border: none;
	outline: 0;
	font-family: $headings-font-family;
}

.button-default {
	padding: .75rem 4rem;
	border: 1.5px solid #cbcfd3;
	border-radius: 1.56rem;
}

.button-big-blue {
	padding: 1rem 2rem;
	color: $blue;
	font-weight: bold;
	font-size: .82rem;
	line-height: .5;

	.close {
		position: relative;
		font-size: 1.5rem;
		top: .2rem;
		margin-right: .25rem;
	}
}

.button-big-yellow {
	padding: 1.65rem 2rem;
	text-transform: uppercase;
	font-weight: 600;
	background: linear-gradient(to bottom, rgba(255, 220, 25, 100%) 0%, rgba(255, 175, 0, 100%) 100%);
	text-align: center;
	border-radius: 7px;
}

.button-reservation {
	font-size: .875rem;
}

.button-small-yellow {
	padding: .5rem 1.25rem;
	text-transform: uppercase;
	font-weight: 600;
	background: linear-gradient(to bottom, rgba(255, 220, 25, 100%) 0%, rgba(255, 175, 0, 100%) 100%);
	text-align: center;
	border-radius: 7px;
}

.button-light {
	padding: .75rem 1rem;
	border-radius: 7px;
	background-color: #e2e2e2;
	line-height: 1;

	.icon {
		width: 2rem;
		height: 2rem;
	}
}

.button-logo {
	padding: .75rem 1rem;
	border-radius: 7px;
	background-color: white;
	line-height: 1;
	text-align: center;

	img {
		height: 1.75rem;
	}
}

.button-destination {
	border: 0;
	background: none;
	//white-space: nowrap;
	display: flex;
	align-items: center;
	margin-bottom: 1.5rem;
	transition: all 150ms ease-in-out;

	&.map-hover-x {
		opacity: .2;
	}

	&.map-hover {
		opacity: 1;
	}

	.circle {
		flex: 0 0 3.3rem;
		display: inline-flex;
		width: 3.3rem;
		height: 3.3rem;
		border-radius: 50%;
		background: gray;
		margin-right: 1rem;

		&.circle-color__ch {
			background-color: #893089;
		}

		&.circle-color__pi {
			background-color: #ffc900;
		}

		&.circle-color__su {
			background-color: #47824e;
		}

		&.circle-color__kr {
			background-color: #c9a951;
		}

		&.circle-color__li {
			background-color: #007bca;
		}

		&.circle-color__to {
			background-color: #f48244;
		}

		&.circle-color__tr {
			background-color: #e82717;
		}

		&.circle-color__ck {
			background-color: #3b8375;
		}

		&.circle-color__nh {
			background-color: #9c3;
		}
	}

	.text {
		display: inline-block;
	}
}
