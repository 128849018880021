.steps {
	display: flex;
	gap: 4rem;
	justify-content: center;
	align-items: start;
	flex-wrap: wrap;

	@media all and (max-width: 1024px) {
		gap: 1rem;
	}
}

.step-col {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
}

.step {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 300px;
	background-color: #018ad3;
	color: white;
	padding: 1rem 2rem;
	text-align: center;
	font-size: 1.75rem;
	font-weight: 700;
	border-radius: .5rem;
	margin-bottom: 1rem;

	@media all and (max-width: 1024px) {
		font-size: 1.25rem;
	}
}

.step-description {
	width: 300px;
}

.step-icon {
	display: flex;
	justify-content: center;
	margin-bottom: .5rem;

	img {
		display: block;
		width: 48px;
		height: auto;

		@media all and (max-width: 1024px) {
			width: 32px;
		}
	}
}

.button-download {
	display: inline-block;
	background-color: #018ad3;
	color: white;
	padding: .5rem 1rem;
	border-radius: .5rem;
	text-decoration: none;
	font-size: 1.25rem;
	transition: all .2s ease-in-out;

	&:hover {
		background-color: #024f77;
		color: white;
	}

	img {
		width: 32px;
		height: auto;
		margin-right: 1rem;
	}

	@media all and (max-width: 1024px) {
		font-size: 1rem;

		img {
			width: 24px;
			height: auto;
			margin-right: .5rem;
		}
	}

	@media all and (max-width: 480px) {
		width: 100%;
		text-align: left;
	}
}
