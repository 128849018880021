.page,
.detail-page {
	padding-bottom: 4rem;
}

.page {
	line-height: 1.75;

	&:not(.full) {

		.container {
			max-width: 800px;
		}
	}

	header {
		padding-top: 6rem;
		text-align: center;

		h1 {
			font-size: 2rem;
			margin-bottom: 1.5rem;

			@media (min-width: map-get($grid-breakpoints, md)) {
				font-size: 3.5rem;
			}
		}

		.lead {
			font-size: 1.12rem;
			margin-bottom: 2rem;
		}

		ol,
		ul {
			text-align: left;
		}
	}
}

@media (min-width: map-get($grid-breakpoints, lg)) {

	.detail-page {

		article {
			width: 75%;
			padding-right: 3rem;
		}

		aside {
			width: 25%;
		}
	}
}

.detail-page {

	aside {
		max-width: 380px;
	}
}
