.active-filter {
	background-color: $blue;
	border-radius: 8px;
	line-height: 1;
	padding: .75rem .6rem;
	color: white;

	.close {
		color: white;
		font-size: 1.5rem;
		padding: 0;
		margin-top: -.7rem;
	}

	.name {
		padding-right: 1rem;
		font-weight: bold;
		text-transform: uppercase;
		font-size: .65rem;
		opacity: .6;
		margin-bottom: .4rem;
	}

	.value {
		font-size: .94rem;
		font-weight: 600;
	}

	@media (max-width: map-get($grid-breakpoints, md)) {
		padding: .6rem .4rem;

		.name {
			font-size: .5rem;
		}

		.value {
			font-size: .8235rem;
		}
	}
}
