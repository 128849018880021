.menu-height {
	height: 5.25rem;

	@media (min-width: map-get($grid-breakpoints, lg)) {
		height: 7.5rem;
	}
}

.main,
nav.mobile,
nav.whatDo {
	position: absolute;

	@media (min-width: map-get($grid-breakpoints, lg)) {
		position: fixed;
	}
}

.main {
	width: 100%;
	z-index: 1000;
	background-color: var(--theme-green);
	box-shadow: 0 0 10px rgba(black, 20%);

	&__wrapper {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
	}

	&__items {
		display: flex;
		flex-flow: row nowrap;
		transform: translateY(30%);
		gap: 3rem;

		@media (max-width: map-get($grid-breakpoints, xl)) {
			transform: translateY(50%);
		}

		@media (max-width: map-get($grid-breakpoints, lg)) {
			display: none;
		}
	}

	.button {
		display: inline-flex;
		font-size: 1.5rem;
		white-space: nowrap;
		color: #fff;

		@media (max-width: map-get($grid-breakpoints, xl)) {
			font-size: 1.25rem;
		}
	}

	.logo {
		display: block;
		z-index: 100;

		img {
			z-index: 10;
			height: auto;
			width: 180px;
			transition: all .3s ease-in-out;

			&.locale-en {
				transform: translateY(3px);
			}

			&.locale-de {
				transform: translateY(5px);
			}

			@media (min-width: map-get($grid-breakpoints, lg)) {
				width: 250px;

				&.locale-en {
					transform: translateY(6px);
				}

				&.locale-de {
					transform: translateY(7px);
				}
			}
		}
	}
}

nav.whatDo {
	display: none;
	padding-top: 5rem;
	padding-bottom: 5rem;
	z-index: 900;
	margin-top: 3.5rem;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 20%);

	@media (min-width: map-get($grid-breakpoints, lg)) {
		margin-top: 5.1765rem;
	}

	ul {
		padding-left: 1.5rem;

		li {
			margin-bottom: .5rem;
		}

		a {
			text-decoration: none;
			font-size: 1.1rem;
			font-weight: bold;
			color: #018ad3;
		}
	}
}

nav.mobile {
	display: none;
	padding-top: 5rem;
	padding-bottom: 5rem;
	margin-top: 3.5rem;
	z-index: 1;

	li {
		margin-bottom: .5rem;
	}

	.button {
		display: block;
		width: 100%;
		text-decoration: none;
		font-size: 1.1rem;
		font-weight: bold;
		text-align: center;
		color: #018ad3;
		padding: 0;
	}
}

.menu-burger {
	position: absolute;
	width: 3rem;
	height: 3rem;
	top: .25rem;
	right: .75rem;
	background: none;
	border: none;
	padding: 1rem .75rem;

	span {
		display: block;
	}

	> span {

		> span {
			background-color: #fff;
			height: 3px;
			margin-bottom: 5px;
		}
	}

	&.x {
		// TODO: make x from elements
	}
}

.nav-fixed-right {
	display: flex;
	position: absolute;
	z-index: 1001;
	text-align: center;
	top: 1.125rem;
	right: 4rem;

	@media (min-width: map-get($grid-breakpoints, lg)) {
		top: 10px;
		right: .75rem;
	}

	.bt {
		font-size: 13px;
		white-space: nowrap;
		border: 2px solid #fff;
		background: transparent !important;
		padding: .25rem 2rem;
		border-radius: 1rem;
		color: #fff;
		line-height: 1;
		font-weight: 600;
		font-family: $headings-font-family;

		@include caret(down);

		&.active {
			@include caret(up);
		}

		@media (max-width: map-get($grid-breakpoints, lg)) {
			padding: .25rem 1rem;
		}
	}

	.list-wrapper {
		display: none;
		background-color: #e9e9e9;
		padding-top: .5rem;
		padding-bottom: .5rem;
		width: 100%;
		position: absolute;
		top: 2rem;
		right: 0;
		border-radius: 1rem;
		box-shadow: 0 0 10px rgba(black, 20%);

		.language-menu {
			display: flex;
			flex-flow: column nowrap;
			margin: 0;

			&__item {
				display: flex;
				width: 100%;
				border-bottom: 1px solid #fff;

				&:last-child {
					border-bottom: none;
				}
			}

			&__link {
				display: flex;
				justify-content: center;
				width: 100%;
				color: #000;
				text-decoration: none;
				text-align: center;
				font-family: $headings-font-family;
				padding: .25rem 0;

				&:hover {
					color: var(--theme-green);
				}

				&.active {
					color: var(--theme-green);
				}
			}
		}

		@media (min-width: map-get($grid-breakpoints, lg)) {
			left: inherit;
			right: 0;
			//
			//@media (max-width: 1500px) {
			//	right: 48px;
			//}
			//
			//@media (max-width: 1280px) {
			//	right: 8px;
			//}
		}
	}
}
