.contact-card {
	border-left: 4px solid #e2e2e2;
	font-size: .85rem;
	color: #363636;
	line-height: 1.7;
}

.contact-card__list {

	.contact-card__list-icon {
		padding-left: 1.5rem;

		&.icon-phone {
			background: url("../../images/icon-phone.svg") no-repeat left center;
		}

		&.icon-email {
			background: url("../../images/icon-email.svg") no-repeat left center;
		}

		&.icon-link {
			background: url("../../images/icon-link.svg") no-repeat left center;
		}
	}
}
