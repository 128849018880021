.subpage-banner {
	aspect-ratio: 21/9;
	position: relative;
	margin-bottom: 4rem;
	overflow: hidden;

	img {
		display: block;
		position: absolute;
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	@media (min-width: map-get($grid-breakpoints, md)) {
		aspect-ratio: 480/101;
	}

	h1 {
		position: absolute;
		bottom: .5rem;
		text-align: center;
		width: 100%;
		font-size: 1.15rem;
		color: white;
		text-shadow: 0 10px 20px rgba(0, 0, 0, 68%);

		@media (min-width: map-get($grid-breakpoints, md)) {
			bottom: 1rem;
			font-size: 2rem;
		}

		@media (min-width: map-get($grid-breakpoints, lg)) {
			bottom: 2.35rem;
			font-size: 3.53rem;
		}
	}
}
