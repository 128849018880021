.datepicker-cell.today:not(.selected),
.datepicker-cell.today:not(.selected):not(.disabled),
.datepicker-cell.today.focused:not(.selected) {
	background-color: white;
	color: #018bd4;
}

.datepicker-cell.prev.selected,
.datepicker-cell.next.selected {
	color: #363636;
	opacity: .6;
}

.datepicker-cell.selected:hover,
.datepicker-cell.selected {
	background: linear-gradient(to bottom, rgba(255, 220, 25, 100%) 0%, rgba(255, 175, 0, 100%) 100%);
	color: #363636;
}
