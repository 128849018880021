.dest-header {
	padding-top: 3rem;
	padding-bottom: 3rem;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;

	&.big {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
}

.dest-header__nh {
	background-image: url("../../images/grey-map-nh.svg");
}

.dest-header__ch {
	background-image: url("../../images/grey-map-ch.svg");
}

.dest-header__ck {
	background-image: url("../../images/grey-map-ck.svg");
}

.dest-header__kr {
	background-image: url("../../images/grey-map-kr.svg");
}

.dest-header__li {
	background-image: url("../../images/grey-map-li.svg");
}

.dest-header__pi {
	background-image: url("../../images/grey-map-pi.svg");
}

.dest-header__su {
	background-image: url("../../images/grey-map-su.svg");
}

.dest-header__to {
	background-image: url("../../images/grey-map-to.svg");
}

.dest-header__tr {
	background-image: url("../../images/grey-map-tr.svg");
}
