.trip-entry {
	display: block;
	position: relative;
	transition: all .2s ease-in-out;
	outline: 2px solid transparent;
	outline-offset: 0;

	img {
		width: 100%;
	}

	> span {
		position: absolute;
		display: flex;
		width: 100%;
		height: 100%;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0%) 0%, rgba(0, 0, 0, 35%) 50%, rgba(0, 0, 0, 0%) 100%);
		align-items: center;

		> span {
			width: 100%;
			color: white;
			font-weight: bold;
			font-size: 1.42rem;
			text-transform: uppercase;
			text-align: center;
			height: auto;
		}
	}

	@media (min-width: map-get($grid-breakpoints, md)) {

		&:hover,
		&:focus,
		&:focus-visible {
			outline: 2px solid white;
			outline-offset: -15px;
		}
	}
}
