.list-arrow {
	list-style: none;
	padding-left: 1rem;

	li {
		padding-bottom: .5rem;

		&:before {
			position: absolute;
			margin-left: -1rem;
			margin-top: .35rem;
			display: block;
			content: "";
			width: 0;
			height: 0;
			border-top: 6px solid transparent;
			border-bottom: 6px solid transparent;
			border-left: 8px solid #cbcfd3;
		}
	}
}
