.icon {
	display: inline-block;
	width: 1rem;
	height: 1rem;
	margin-right: .3rem;
	position: relative;
	top: 2px;
}

.icon-locality {
	background: url("../../images/icon-locality.svg") center no-repeat;
}

.icon-calendar {
	background: url("../../images/icon-calendar.svg") center no-repeat;
}

.icon-blue-pin {
	background: url("../../images/icon-blue-pin.svg") center no-repeat;
}

.icon-blue-navi {
	background: url("../../images/icon-blue-navi.svg") center no-repeat;
}

.icon-blue-pins {
	background: url("../../images/icon-blue-pins.svg") center no-repeat;
}

.bg-icon {
	padding-left: 2rem;

	&.bg-icon-locality {
		background: url("../../images/icon-locality.svg") left top no-repeat;
		background-size: 1rem auto;
	}

	&.bg-icon-calendar {
		background: url("../../images/icon-calendar.svg") left top no-repeat;
		background-size: 1rem auto;
	}
}
