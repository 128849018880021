.titled-card {
	display: block;
	position: relative;
	overflow: hidden;

	img {
		width: 100%;
		transform: scale(1.07);
		transition: transform .2s ease-in-out;
		margin: 0 !important;
	}

	span {
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 1rem;
		text-align: center;
		backdrop-filter: blur(15px);
		background-color: rgba(0, 0, 0, 40%);
		color: white;
		font-size: 1.12rem;
		font-weight: bold;

		&.small {
			padding: .75rem;
			font-size: .9rem;
		}
	}

	@media (min-width: map-get($grid-breakpoints, md)) {

		&:hover,
		&:focus {

			img {
				transform: scale(1);
			}
		}
	}
}
