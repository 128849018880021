.top-banner {
	position: relative;
	margin-bottom: 2rem;

	@media (min-width: map-get($grid-breakpoints, md)) {
		margin-bottom: 10rem;
	}

	.top-banner-wrapper {
		position: relative;
		width: 100%;
		aspect-ratio: 640/249;
		overflow: hidden;

		img {
			position: relative;
			left: 50%;
			transform: translateX(-50%);
			min-width: 100%;
			height: 100%;
			object-fit: cover;
		}

		h1 {
			position: absolute;
			bottom: 1.5rem;
			text-align: center;
			width: 100%;
			font-size: 1.6rem;
			color: white;
			text-shadow: 0 5px 10px rgba(0, 0, 0, 68%);
			margin: 0;

			@media (min-width: map-get($grid-breakpoints, md)) {
				font-size: 2.875rem;
				text-shadow: 0 10px 20px rgba(0, 0, 0, 68%);
				bottom: 4.5rem;
			}

			@media (min-width: map-get($grid-breakpoints, lg)) {
				font-size: 4rem;
				bottom: 8rem;
			}

			@media (min-width: map-get($grid-breakpoints, xl)) {
				font-size: 5.88rem;
				bottom: 10rem;
			}
		}

		&.thin {
			aspect-ratio: 21/9;

			@media (min-width: map-get($grid-breakpoints, md)) {
				aspect-ratio: 30/9;

				h1 {
					bottom: 7.5rem;
				}
			}

			@media (min-width: map-get($grid-breakpoints, xl)) {
				aspect-ratio: 480/101;

				h1 {
					font-size: 4rem;
				}
			}
		}
	}
}

.finder {
	margin-top: 0;

	@media (min-width: map-get($grid-breakpoints, md)) {
		margin-top: -3.5rem;
	}

	@media (min-width: map-get($grid-breakpoints, lg)) {
		margin-top: -6.5rem;
	}
	//@media (min-width: map-get($grid-breakpoints, md)) {
	//	position: absolute;
	//	bottom: 0;
	//	transform: translateY(50%);
	//	width: 100%;
	//}
}

.finder-wrapper {
	width: 100%;
	max-width: 800px;
	background-color: rgba(0, 0, 0, 40%);
	margin: 1rem auto;
	padding: 2rem;
	color: white;

	@media (min-width: map-get($grid-breakpoints, md)) {
		padding: 2rem 7rem;
		margin: 0 auto;
		backdrop-filter: blur(15px);
	}

	.input {
		width: 100%;
		background-color: white;
		height: 55px;
		border-radius: 27.5px;
		padding: 12px 24px;
		display: flex;

		input {
			padding: 0;
			width: 100%;
			border: none;
			appearance: none;

			&:focus {
				outline: none;
			}
		}
	}

	.button {
		line-height: 1;
	}

	.intro,
	.most {
		text-align: center;
	}

	a.badge {
		text-decoration: none;
		margin-bottom: .5rem;

		&:hover {
			color: white;
			opacity: .8;
		}
	}
}

.top-banner-gallery {
	display: flex;
	flex-wrap: wrap;
	position: absolute;
	background: white;
	padding: 0;
	left: 45px;
	bottom: 36px;
	border: 1px solid white;

	a {
		display: flex;
		border: 1px solid white;

		img {
			width: 78px;
			height: 78px;
			object-fit: cover;
		}
	}
}
