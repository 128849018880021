.filter-bar {
	background: #f7f7f7;
	border-top: 1px solid #e2e2e2;
	border-bottom: 1px solid #e2e2e2;
	padding: 1rem 0;

	@media (min-width: map-get($grid-breakpoints, md)) {
		padding: 2rem 0;
	}
}
