.project-logos {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1rem;
	max-width: 900px;
}

.project-logos-item {
	margin-bottom: 2rem;
	padding: 0 2rem;
	text-align: center;

	img {
		display: inline-block;
		height: 75px;
		width: auto;

		@media all and (max-width: 768px) {
			height: 50px;
		}
	}
}
