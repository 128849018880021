.footer-cta {
	background: var(--theme-grey);
	padding: 3.75rem 0;

	@media screen and (max-width: 580px) {
		padding: 2rem 0;
	}
}

.footer-cta__wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
	gap: 2rem;

	@media screen and (max-width: 580px) {
		gap: 1rem;
	}
}

.footer-cta__title {
	font-size: 5rem;
	color: var(--theme-orange);
	margin: 0;

	@media screen and (max-width: 1024px) {
		font-size: 4rem;
	}

	@media screen and (max-width: 580px) {
		font-size: 2rem;
	}
}

.footer-cta__buttons {
	display: flex;
	flex-direction: row;
	flex-shrink: 0;
	justify-content: flex-end;
	gap: 1.125rem;

	@media screen and (max-width: 1024px) {
		justify-content: space-between;
		width: 100%;
	}
}

.footer-cta__button {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--theme-green);
	padding-left: 5rem;
	padding-right: calc(5rem - 25px);
	border-radius: 1.25rem;
	height: 10rem;

	img {
		display: block;
		width: 100%;
		height: auto;
	}

	&--jccr {
		padding-right: 5rem;
	}

	&--jk {

		img {
			transform: translateY(4px);
		}
	}

	@media screen and (max-width: 768px) {
		padding-left: 3rem;
		padding-right: calc(3rem - 18px);
		height: 7rem;

		&--jccr {
			padding-right: 3rem;
		}
	}

	@media screen and (max-width: 580px) {
		padding-left: 1.375rem;
		padding-right: calc(1.375rem - 12px);
		height: 6rem;

		&--jccr {
			padding-right: 1.375rem;
		}
	}

	@media screen and (max-width: 475px) {
		height: 4.125rem;
	}
}
