.item-content {
	margin-bottom: 2rem;

	&.bg-content {
		background: #fff4dd;
		padding: 1.5rem;
		border-radius: 7px;
	}
}

.item-content-heading {
	padding-left: 1.5rem;

	&.icon-cap {
		padding-left: 3.5rem;
		background: url("../../images/icon-cap.svg") left center no-repeat;
		background-size: auto 100%;
	}
}
