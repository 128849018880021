#app-footer {
	background: var(--theme-green);
	color: #fff;
	padding: 2.625rem 0 3.5rem;
}

.footer__wrapper {
	display: flex;
	flex-flow: row wrap;

	@media all and (max-width: 992px) {
		flex-direction: column;
		gap: 2rem;
	}
}

.footer__col {
	flex: 0 0 50%;
	max-width: 50%;

	&--left {
		flex: 0 0  60%;
		max-width: 60%;
		padding-right: 2rem;
	}

	&--right {
		flex: 0 0 40%;
		max-width: 40%;
		padding-left: 2rem;
		min-height: 250px;
	}

	@media all and (max-width: 992px) {

		&--left,
		&--right {
			flex: 0 0 100%;
			max-width: 100%;
			padding: 0;
		}
	}
}

.footer__heading {
	font-size: 1.5rem;
	font-weight: 600;
	margin-bottom: .5rem;
}

.footer__credits {
	font-size: .875rem;
	margin-top: 1rem;
	margin-bottom: 0;

	a {
		color: #fff;
	}
}

.footer__theme-of-year {
	display: block;
	position: relative;
	color: #fff;
	aspect-ratio: 547/269;
	width: 100%;
	max-width: 400px;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 20%);
	}

	img {
		width: 100%;
		max-width: 547px;
		height: auto;
	}

	span {
		position: absolute;
		top: 1.125rem;
		left: 1.375rem;
		font-family: $headings-font-family;
		font-size: 1.5rem;
	}

	&:hover {
		text-decoration: none;
		color: #fff;
	}
}
