.card-title {
	font-weight: 600;
	min-height: 2 * 1.5em;
	overflow: hidden;
	display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
}

.card {
	border: 1px solid #cbcfd3;

	img {
		margin: 0;
	}
}

.card-content {
	position: relative;
	overflow: hidden;

	&:after {
		display: block;
		content: "";
		position: absolute;
		bottom: 0;
		left: 1rem;
		right: 1rem;
		border-top: 1px solid #cbcfd3;
	}
}

.card-footer {
	position: relative;
	padding: 1rem;
}

.card-period:before {
	position: absolute;
	content: "";
	top: 0;
	right: 0;
	width: 3rem;
	height: 3rem;
	background: url("../../images/icon-annual.svg") no-repeat;
	background-size: contain;
}

.card-period-summer:before {
	background-image: url("../../images/icon-summer.svg");
}

.card-period-winter:before {
	background-image: url("../../images/icon-winter.svg");
}
