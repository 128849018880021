.colored-line {

	div {
		height: 6px;
		background-color: #003380;

		&:nth-child(2) {
			background-color: #ffb819;
		}

		&:nth-child(3) {
			background-color: #4e9d2d;
		}

		&:nth-child(4) {
			background-color: #c3002f;
		}
	}
}
