figure.media {

	iframe {
		display: block;
		width: 100%;
		max-width: 720px;
		height: auto;
		aspect-ratio: 16/9;
		margin: 1rem auto;
	}
}

figure.image {
	position: relative;
	min-width: 50px;
	text-align: center;
	margin: 1rem auto;

	img {
		display: block;
		height: auto;
		margin: 0 auto;
		max-width: 100%;
		min-width: 100%;
	}

	&.image_resized {
		max-width: 100%;

		img {
			width: 100%;
			height: auto;
		}
	}

	&.image-style-align-left {
		float: left;
		margin-right: 2rem;
	}

	&.image-style-align-right {
		float: right;
		margin-left: 2rem;
	}
}

p + .image-style-align-left,
p + .image-style-align-right {
	margin-top: 0;
}

figcaption {
	font-size: .8rem;
	color: #6c757d;
	text-align: center;
}

iframe {
	display: block;
	width: 100%;
	height: auto;
	aspect-ratio: 16/9;
	margin: 1rem auto;
}

.page-break {
	page-break-before: always;
	clear: both;
}
