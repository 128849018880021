.trip-map {
	position: relative;
	width: 100%;
	padding-bottom: 100%;
	margin-bottom: 1rem;
	overflow: hidden;
	border-radius: 7px;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
