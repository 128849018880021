.instagram-wrapper {
	margin-bottom: 2.625rem;
}

.instagram-image {
	display: block;
	position: relative;
	float: left;
	width: 16.6666%;

	@media all and (max-width: map-get($grid-breakpoints, xl)) {
		width: 25%;
	}

	@media all and (max-width: map-get($grid-breakpoints, md)) {
		width: 33.3333%;
	}

	&:after {
		display: block;
		position: absolute;
		content: " ";
		width: 18px;
		height: 18px;
		left: 10px;
		bottom: 10px;
		background: url("../../images/icon-instagram.svg") no-repeat center;
		background-size: 18px 18px;
	}

	img {
		display: block;
		width: 100%;
		height: 100%;
	}
}
